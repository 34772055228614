import { graphql } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Callout from '../components/Callout';
import EpisodePlayer from '../components/EpisodePlayer';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';

const textOverlay = (
  <div className="master-class__text_overlay_container">
    <Container fullWidth>
      <div className="master-class__text_overlay_info">Masterclass</div>
    </Container>
  </div>
);

const subfooterContent = (
  <>
    <span>
      You can use Discover Data in any curriculum—I use it in everything from
      research to psychology to global studies.
    </span>
    <br />
    <span className="subfooter-content-author">
      — Becky Plotkin, Instructional Facilitator
    </span>
    <br />
    <span className="subfooter-content-author">Pine Lake Preparatory</span>
  </>
);

const Test = ({ data }) => {
  return (
    <Layout title="Masterclass">
      <Hero
        mediaSource="img-lead-triangle-master-class.png"
        textOverlay={textOverlay}
      />
      <Section className="master-class__callout-section section--gray">
        <Container fullWidth>
          <Row className="master-class__callout-row">
            <Column largeOffset={1} large={10}>
              <Callout
                className="master-class__callout"
                containerClassName="master-class__callout-container"
              >
                Watch data come to life with our masterclass series
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <EpisodePlayer
            description={''}
            className="pt-0"
            episodes={data.allMasterClassYaml.edges.map((edge) => edge.node)}
            guideImg="vts-resource-EdGuide.jpg"
            guideTitle="Masterclass educator guide"
            guideSubtitle="Extend the learning with key curriculum connections."
            guideDescription="Learn more about the themes explored in the Masterclass video using this guide, featuring helpful background information and resources for activating students in the classroom."
            guideAudience="Educators"
            guideDownloadUrl="/sites/default/files/documents/Nielsen-Masterclass-EdGuide.pdf"
            guideSize="484 KB"
            guideDuration="Flexible time"
            guideSubjectArea="INSTRUCTIONAL TOOLS"
            about="Explore the ways educators can leverage data in the classroom to drive discussion and bridge diverse topic areas with this Masterclass video series. Learn effective ways to incorporate Discover Data resources into your curriculum by going inside the classrooms of two educators who are passionate about the possibilities of data."
          />
        </Container>
      </Section>

      <Section>
        <Container>
          <Button
            className="master-callout"
            to="/educators#curriculum-connectors"
          >
            Start using data in your classroom! Check out the classroom
            activities.
            <Icon name="arrowright" marginRight />
          </Button>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row id="educator-webinar" className="mb-4">
            <Column size={4}>
              <Image filename="master-webinar-thumb.png" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">Educator webinar</h2>
              <div className="resource__duration">
                <Icon name="clock" marginRight /> Flexible Time
              </div>
              <p className="text-sans-serif">
                Looking for ways to adapt lessons for a virtual classroom?
                DISCOVER DATA….Remotely! is the latest edition in our webinar
                series. With overviews on how to use popular digital tools with
                DISCOVER DATA resources, our goal is to provide you with
                concrete ideas for engaging your students virtually.
              </p>
              <p className="mb-0 text-sans-serif">
                <strong>Webinar highlights</strong>
              </p>
              <ul className="pl-2 text-sans-serif">
                <li>
                  Overview the key points of FERPA (The Family Educational
                  Rights and Privacy Act)
                </li>
                <li>
                  Our Top 10 digital tools for using DISCOVER DATA remotely
                </li>
                <li>
                  Making Career Connections: Virtually connecting your class
                  with real-world data professionals
                </li>
              </ul>

              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="pl-1">Watch now</span>
                  </Button>
                }
              >
                <Video
                  guidSrc="5eead242-1cf8-41a7-89b4-8f811b653a3e"
                  poster="video-poster-webinar@2x.jpg"
                  video={true}
                />
              </Modal>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section className="mb-0 pb-0 master-class__subfooter">
        <Subfooter
          img="footer-master.jpg"
          content={subfooterContent}
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query MasterClassQuery {
    allMasterClassYaml {
      edges {
        node {
          id
          dexSrc
          importantThemes
          posterSrc
          subtitle
          title
          description
          duration
          instructor {
            name
            role
            school
            background
            image
          }
        }
      }
    }
  }
`;

export default Test;
